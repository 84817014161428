<template>
    <div>
        <p id="thumbnailHeader" class="pb-3">Upload Thumbnail Image</p>

        <dashboard  id="thumbnailDash" :uppy="uppy" :props="{
                theme: 'light',
                height: 250,
                showProgressDetails: true,
                autoOpenFileEditor: true,
                 doneButtonHandler: () => {
                 }}"/>
    </div>


</template>

<script>
import {Dashboard} from '@uppy/vue'
import getBlobDuration from 'get-blob-duration'
import { openDefaultEditor } from '../../../public/pintura-cropper/uppy/pintura.js';

import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import "@uppy/webcam/dist/style.css";
import "@uppy/image-editor/dist/style.css";
import ThumbnailGenerator from '@uppy/thumbnail-generator'
import FileInput from '@uppy/file-input'


import XHRUpload from "@uppy/xhr-upload";
import ImageEditor from "@uppy/image-editor";


import Uppy from '@uppy/core'
import Webcam from '@uppy/webcam'
// import toas

export default {
    name: "ProfileVideoThumbnail",
    props: ['csrfToken', 'agentId'],
    components: {
        Dashboard
    },
    data() {
        return {
            showVideoDashboard: true,
        }
    },
    mounted() {
        // $('#thumbnailDash').hide();
        // $('#thumbnailHeader').hide();

    },
    computed: {
        uppy: function () {
             window.uppy = new Uppy({
                restrictions: {
                    maxNumberOfFiles: 1,
                    minNumberOfFiles: 0,
                    // allowedFileTypes: ['image/*', 'image/heic', '.heic']
                    // allowedFileTypes: [
                    //     'image/jpeg','image/png',
                    //     'audio/mpeg','audio/x-wav',
                    //     'video/mp4','video/quicktime','video/x-msvideo','video/webm'
                    // ]
                },
                onBeforeFileAdded:  useEditorWithUppy(
                    // Method used to create Pintura Image Editor
                    openDefaultEditor,

                    // The editor options
                    {
                        imageCropAspectRatio: 1,
                    },
                    // Reference to Uppy addFile API
                    function () {
                        return true;
                    }
                ),
            })
            // uppy.on('file-added',  async function (selected) {
                // console.log(selected)
                // selected.then((file) => console.log(file))
                // console.log(selected);
                // const extension = selected.
                //  if (extension == 'heic') {
                //     // Let's turn the HEIC image into JPEG so the browser can read it
                //     await heic2any({
                //         blob: selectedFile,
                //         toType: 'image/jpeg',
                //         quality: 0.94,
                //     }).then(blob => {
                //         file.data =  $.fn.pintura.blobToFile(blob, selectedFile.name)
                //         queueFile(file)
                //     });
                // }
            // }).then(resp => console.log(resp));

            uppy.on('file-added', (file) => {
                console.log('Added file', file)
            })

            uppy.use(ThumbnailGenerator, {
                id: 'ThumbnailGenerator',
                thumbnailWidth: 200,
                thumbnailHeight: 200,
                thumbnailType: 'image/jpeg',
                waitForThumbnailsBeforeUpload: false,
            });

            uppy.use(XHRUpload, {
                endpoint: "/upload-profile-video?upload_type=thumbnail",
                fieldName: "file",
                validateStatus: (statusCode, responseText, response) => {
                    let error = 'Something went wrong please try again';
                    if (statusCode !== 200) {
                        try {
                            error = JSON.parse(responseText).errors.file[0];
                        } catch (e) {

                        }

                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: error,
                        });
                    }
                    $('#thumbnailModal').modal('toggle');
                    $('.uploaded').hide();
                    $('#processing').show();
                    return true;
                },
                headers: {
                    Accept: 'application/json'
                }
            })
            uppy.setMeta({
                _token: this.csrfToken,
                agent_id: this.agentId
            })
            return uppy;
        }

    },
    methods: {},
    beforeDestroy() {
        this.uppy.close()
    }
}
</script>

<style scoped>

</style>
