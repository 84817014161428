<template>
    <div>
        <dashboard :uppy="uppy" :plugins="['Webcam']" :props="{
                theme: 'light',
                height: 300,
                showProgressDetails: true,
                autoOpenFileEditor: true,
                 doneButtonHandler: () => {
                    uppy.reset();
                 },
                 proudlyDisplayPoweredByUppy: false
            }"/>

    </div>


</template>

<script>
import {Dashboard} from '@uppy/vue'
import getBlobDuration from 'get-blob-duration'

import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import "@uppy/webcam/dist/style.css";
import "@uppy/image-editor/dist/style.css";
import ThumbnailGenerator from '@uppy/thumbnail-generator'
import FileInput from '@uppy/file-input'
import AwsS3Multipart from '@uppy/aws-s3-multipart';



import XHRUpload from "@uppy/xhr-upload";
import ImageEditor from "@uppy/image-editor";

import Uppy from '@uppy/core'
import Webcam from '@uppy/webcam'
import axios from "axios";

export default {
    name: "ProfileVideoRecorder",
    props: ['csrfToken', 'agentId', 'route'],
    components: {
        Dashboard
    },
    data() {
        return {
            showVideoDashboard: true,
        }
    },
    mounted() {
        console.log("here...");
        // $('#thumbnailDash').hide();
        // $('#thumbnailHeader').hide();

    },
    computed: {
        uppy: function () {
            const selected = this.selectedFile;
            let uppy = new Uppy({
                restrictions: {
                    maxNumberOfFiles: 1,
                    minNumberOfFiles: 0,
                    allowedFileTypes: ['video/*']
                    // allowedFileTypes: [
                    //     'image/jpeg','image/png',
                    //     'audio/mpeg','audio/x-wav',
                    //     'video/mp4','video/quicktime','video/x-msvideo','video/webm'
                    // ]
                },
                onBeforeFileAdded: function (currentFile, files) {
                    return true;
                },
            })

            uppy.use(Webcam, {
                title: 'Profile Capture',
                showRecordingLength: true,
                countdown: true,
                preferredVideoMimeType: 'video/avi',
                showVideoSourceDropdown: true,
            })

            uppy.use(FileInput, {
                // Options
                preferredVideoMimeType:'video/avi',

            })

            uppy.use(XHRUpload, {
                endpoint: "/upload-profile-video",
                fieldName: "file",
                validateStatus: (statusCode, responseText, response) => {
                    let error = 'Something went wrong please try again';
                    if (statusCode !== 200) {
                        try {
                            error = JSON.parse(responseText).errors.file[0];
                        } catch (e) {}

                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: error,
                        });
                    }

                    $('#thumbnailDash').show();
                    $('#videoDash').hide();
                    // $('#processing').show();

                    $('.uploaded').hide();
                    $('#processing').show();
                    $('#showMedia').show();
                    $('#updateMedia').hide();

                    // $('#thumbnailHeader').show();



                    // const fileMetaUl = $('#fileMetaUl');
                    //
                    // const data = JSON.parse(responseText).data;
                    // // console.log(data);
                    // fileMetaUl.append(`<li> <span style="font-weight: bold">filename:</span> ${data.filename}</li>`);
                    // fileMetaUl.append(`<li><span style="font-weight: bold">filesize:</span> ${data.filesize}</li>`);
                    // fileMetaUl.append(`<li><span style="font-weight: bold">filetype:</span> ${data.filetype}</li>`);
                    // fileMetaUl.append(`<li><span style="font-weight: bold">duration:</span> ${data.duration}mins</li>`);

                    return true;
                },
                headers: {
                    Accept: 'application/json'
                }
            })
            // uppy.use(AwsS3Multipart, {
            //     limit: 4,
            //     companionUrl: this.route,
            //     companionHeaders: {
            //         'X-CSRF-TOKEN': this.csrfToken
            //     },
            //     completeMultipartUpload(file, {uploadId, key, parts}) {
            //         console.log(file, uploadId, key, parts);
            //     },
            //     listParts(file, { uploadId, key }) {
            //         console.log(file, uploadId, key, parts);
            //     }
            //     // async prepareUploadParts(arg1, arg2) {
            //     //     const response = await axios.get('https://popintros.test/' + `s3/multipart/${arg2.uploadId}/batch?key=${arg2.key}&partNumbers=${arg2.partNumbers}`)
            //     //     return {presignedUrls: response.presignedUrls}
            //     // }
            // });

            uppy.on('thumbnail:generated', function (file, preview) {
                console.log(preview);

            })
            uppy.on('file-added', async function (selected) {
                var video = $('.uppy-Webcam-video  ');
                console.log(video.length);

                if (video.length == 0) {
                    return true;
                }

                const duration = await getBlobDuration(video.attr('src'))
                const durationInMins = duration / 60;

                uppy.setMeta({duration: durationInMins});

                if (durationInMins > 2.01) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Sorry, Your video duration cannot be more that 2mins',
                    });
                    return false;
                }

                return true;
            })
            uppy.setMeta({
                _token: this.csrfToken,
                agent_id: this.agentId
            })

            return uppy;
        },
    },
    methods: {},
    beforeDestroy() {
        this.uppy.close()
    }
}
</script>

<style scoped>

</style>
