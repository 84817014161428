<template>
    <div>
        <div v-if="myCalendar" class="nk-block-head-content pageHead__right">
            <ul class="btn-toolbar gx-sm-1 justify-content-end">
                <li>
                    <button class="btn btn-primary ripple-effect" @click.prevent="edit = false" data-toggle="modal"
                            data-target="#addScheduleOld"
                            id="addScheduleBtn" data-backdrop="static" data-keyboard="false">Add Schedule
                    </button>
                </li>
            </ul>
        </div>
        <VueCal
            ref="vuecal"
            active-view="month"
            :events="events"
            events-on-month-view="short"
            :editable-events="{ title: false, drag: myCalendar, resize: false, create: true }"
            @cell-dblclick="createSchedule($event)"
            :disable-views="['day', 'years']"
            :time="true"
            :dragToCreateThreshold="15"
            :on-event-create="handleCreateEvent"
            @event-change="updateEvent"
            ondragexit="alert('done')"
            click-to-navigate
            today-button
            class="vuecal--green-theme"
            :on-event-click="onEventClick"
        />
        <br>

        <div v-if="myCalendar">
            <p><span class="fa fa-eyedropper"></span>You can add your PopIntros appointments to your calendar application or service by downloading the ICS file below.</p>
            <a :href="`${calendarSyncEndpoint}?all=true`"><span
                class="fa fa-calendar-plus"></span> Download .ics file</a>
        </div>


        <div class="modal fade" tabindex="-1" role="dialog" id="addBlock">
            <div class="modal-dialog modal-dialog-centered modal-md" role="document">
                <div class="modal-content">
                    <a href="#" class="close" data-dismiss="modal"><em class="icon ni ni-cross-sm"></em></a>
                    <div class="modal-body modal-body-lg">
                        <h5 class="title mb-2 mb-md-4">Create a Schedule Series</h5>
                        <!--                        <form id="add-schedule" method="POST">-->
                        <div class="row gy-4 ">
                            <div v-if="question1">
                                <p>Do you want to repeat this schedule?</p>
                                <button @click.prevent="closeAddBlock" class="btn btn-danger">No</button>
                                <button @click.prevent="question1 = false; question2 =true" class="btn btn-success">
                                    Yes
                                </button>
                            </div>
                            <div v-if="question2">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-12">
                                                <label for="">
                                                    <input v-model="bulkType" value="every_day" type="radio">
                                                    Every day in the month
                                                </label>
                                            </div>
                                            <div class="col-12">
                                                <label for="">
                                                    <input v-model="bulkType" value="every_week_day" type="radio">
                                                    Every Week day in the month
                                                </label>
                                            </div>
                                            <div class="col-12">
                                                <label for="">
                                                    <input v-model="bulkType" value="this_day" type="radio">
                                                    Only on this day the rest days in the month
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button type="button" id="addBlockButton" @click="submitBulkSchedule"
                                        class="btn btn-primary width-120 ripple-effect mr-2 btn-add-schedule">
                                    Submit
                                </button>
                            </div>

                            <div class="col-md-12">
                                <div class="text-center mt-4">

                                    <button type="button" data-dismiss="modal"
                                            class="btn btn-light width-120 ripple-effect">Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                        <!--                        </form>-->
                    </div>
                </div>
            </div>
            <!-- .modal-body -->
        </div>
        <div class="modal fade" tabindex="-1" role="dialog" id="deleteConfirm">
            <div class="modal-dialog modal-dialog-centered modal-md" role="document">
                <div class="modal-content">
                    <a href="#" class="close" data-dismiss="modal"><em class="icon ni ni-cross-sm"></em></a>
                    <div class="modal-body modal-body-lg">
                        <p>Are you sure you want to delete this event?</p>
                        <button @click="deleteEvent(deleteBlock);" class="btn btn-danger">Yes</button>
                        <button type="button" data-dismiss="modal"
                                class="btn btn-light width-120 ripple-effect">Cancel
                        </button>
                    </div>
                </div>
            </div>
            <!-- .modal-body -->
        </div>

        <div class="modal fade" tabindex="-1" role="dialog" id="addScheduleOld">
            <div class="modal-dialog modal-dialog-centered modal-md" role="document">
                <div class="modal-content">
                    <a href="#" class="close" data-dismiss="modal"><em class="icon ni ni-cross-sm"></em></a>
                    <div class="modal-body modal-body-lg">
                        <h5 class="title mb-2 mb-md-4">Create Schedule</h5>
                        <form id="add-schedule2" method="POST">
                            <div v-if="!edit" class="row">
                                <div class="form-group">
                                    <label @click="check('singleRadio', 'seriesRadio')" for="">
                                        <input v-model="type" value="single" id="singleRadio" type="radio">
                                        Single Event
                                    </label>
                                    <label @click="check('seriesRadio', 'singleRadio')" for="">
                                        <input v-model="type" value="series" id="seriesRadio" type="radio">
                                        Series Event
                                    </label>
                                </div>
                            </div>
                            <div class="row gy-4 ">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <div class="form-control-wrap">

                                            <input v-model="startDate" :min="new Date().toISOString().split('T')[0]"
                                                   type="date" id="" class="form-control startDate"
                                            >
                                            <div class="error-help-block text-danger"></div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="type == 'series'" class="col-md-12">
                                    <div class="form-group">
                                        <div class="form-control-wrap">
                                            <div class="form-icon form-icon-right">
                                                <em class="icon ni ni-calendar-alt"></em>
                                            </div>
                                            <input v-model="endDate" type="date" class="form-control endDate">
                                            <div class="error-help-block text-danger"></div>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="type == 'series'" class="col-md-12">
                                    <div class="form-group selectDay d-flex">
                                        <div @click="check(`weekday-${key}`)" v-for="(weekday, key) in week"
                                             class="custom-control custom-checkbox">
                                            <input type="checkbox" :id="`weekday-${key}`" name="days[]" :value="weekday"
                                                   :class="{days: true,  'custom-control-input': true}"
                                                   :checked="isChecked(weekday)">
                                            <label class="custom-control-label" for="">{{ weekday }}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <div class="">
                                            <label for="">Start Time</label>
<!--                                            <input type="time" class="form-control" id="timepicker"-->
<!--                                                   v-model="startTime"-->
<!--                                            >-->
                                            <vue-timepicker id="timepicker" v-model="startTime" ></vue-timepicker>

                                            <div class="error-help-block text-danger"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                        <div class="form-group">
                                            <div class="">
                                                <label for="">End Time</label>
<!--                                                <input type="time" class="form-control" id="timepicker2"-->
<!--                                                       v-model="endTime"-->
<!--                                                >-->
                                                <vue-timepicker id="timepicker2" v-model="endTime" ></vue-timepicker>

                                                <div class="error-help-block text-danger"></div>
                                            </div>
                                        </div>
                                    </div>
                                <div class="col-md-12">
                                    <div class="text-center mt-4">
                                        <button @click.prevent="submitBulkSchedule" type="button" id="btnAddSchedule2"
                                                class="btn btn-primary width-120 ripple-effect mr-2 btn-add-schedule">
                                            {{ !edit ? 'Create' : 'Edit' }}
                                        </button>
                                        <button type="button" data-dismiss="modal"
                                                class="btn btn-light width-120 ripple-effect">Cancel
                                        </button>
                                    </div>
                                </div>
                                <br>
                                <div v-if="edit" class="col-md-12">
                                    <div class="form-group">
                                        <label for="">
                                            <input type="checkbox" v-model="deleteBlock">
                                            Delete Series?
                                        </label><br>
                                        <button @click.prevent="confirmDelete" type="button" class="btn btn-danger width-120 ripple-effect"> Delete Event </button>

                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <!-- .modal-body -->
        </div>

    </div>
</template>

<script>
import '@fullcalendar/core/vdom'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'
import 'vue-cal/dist/drag-and-drop.js'
import Swal from 'sweetalert2'
import axios from "axios";
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

var moment = require('moment');


export default {
    name: "Calendar",
    components: {FullCalendar, VueCal, VueTimepicker},
    props: ['schedules', 'authId', 'myCalendar', 'bulkCreateEndpoint', 'listEventsEndpoint', 'calendarSyncEndpoint', 'createEndpoint', 'updateEndpoint', 'deleteEndpoint', 'token'],
    data() {
        return {
            edit: false,
            type: 'single',
            startDate: null,
            startTime: null,
            endTime: null,
            endDate: null,
            days: [],
            question1: true,
            question2: false,
            question3: false,
            calendar: null,
            calendarKey: 0,
            week: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            defaultDays: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'],
            duration: 30,
            start: new Date(),
            scheduleObj: {
                title: 'new event',
                start: new Date(),
                end: new Date(),
            },
            events: [],
            plugins: [dayGridPlugin, interactionPlugin],
            calendarOptions: {},
            selectedEvent: {},
            bulkType: 'every_day',
            deleteBlock: false,
        }
    },
    created() {
        this.schedules.map((schedule) => {
            schedule.start = new Date(schedule.start);
            schedule.end = new Date(schedule.end);
            this.events.push(schedule);
        })
        this.events = this.schedules;
        this.defaultDays.map((day) => this.days.push(day))
    },
    mounted() {

        if (this.myCalendar) {
            setTimeout(() => {
                this.refreshEvents();
            }, 5000)
        }
    },
    methods: {
        updateEvent(info) {
            if (info.originalEvent != null) {

                const event = info.event;
                axios.post(this.updateEndpoint, {
                    _token: this.token,
                    id: event.id,
                    start: new Date(event.start).toISOString(),
                    end: new Date(event.end).toISOString(),
                }).then((response) => {

                }).catch(err => {
                    console.log(err.resources)
                    Swal.fire({
                        title: 'Error!',
                        text: 'Could not create your event. Please try again',
                        icon: 'error',
                        confirmButtonText: 'Close'
                    })
                })
            }
        },
        refreshEvents() {
            axios.get(this.listEventsEndpoint)
                    .then((resp) => this.events = resp.data.data)
                    .catch(err => console.log(err));
        },
        handleCreateEvent(event, deleteEvent) {
            console.log(event);
            const eventId = event._eid;
            let created = true;
            axios.post(this.createEndpoint, {
                _token: this.token,
                start: new Date(event.start).toISOString(),
                end: new Date(event.end).toISOString(),
            }).then((response) => {
                this.selectedEvent = response.data.data;
            }).catch(err => {
                created = false;
                console.log(err)
                Swal.fire({
                    title: 'Error!',
                    text: 'Could not create your event. Please try again',
                    icon: 'error',
                    confirmButtonText: 'Close'
                })
            }).finally(() => {
               this.refreshEvents();
            })
            return created ? event : false;
        },
        createSchedule(event) {
            if(this.myCalendar) {
                this.$refs.vuecal.createEvent(
                    event,
                    30,
                    {id: Math.random(), title: 'Available', class: 'bg-primary'}
                )
            }
        },
        onEventClick(event, e) {
            if (this.myCalendar) {
                $('#addScheduleOld').modal();
            } else {
                $('#addSchedule').modal();
                $('#addSchedule').css("z-index", "1500");
            }
            this.edit = true;

            this.selectedEvent = {...event};
            this.$emit('selected', this.selectedEvent)
            const hrDiff = this.selectedEvent.end.getHours() - this.selectedEvent.start.getHours();

            this.type = 'single';
            this.startDate = moment(this.selectedEvent.start).format('YYYY-MM-DD');

            this.startTime = moment(this.selectedEvent.start).format('HH:mm');
            this.endTime = moment(this.selectedEvent.end).format('HH:mm');

            this.duration = (this.selectedEvent.end.getMinutes() - this.selectedEvent.start.getMinutes()) + (60 * hrDiff);
        },
        syncCalendar() {
            axios.post(this.calendarSyncEndpoint, {
                _token: this.token,
                start: this.selectedEvent.start,
                end: this.selectedEvent.end,
                date: this.selectedEvent.date,
            }).then((response) => {
                response.data.data.map(id => {
                    this.events = this.events.filter((event) => event.id != id);
                })
                this.resetQuestions();
            }).catch(err => {
                console.log(err.resources)
                Swal.fire({
                    title: 'Error!',
                    text: 'Could not delete your event. Please try again',
                    icon: 'error',
                    confirmButtonText: 'Close'
                })
            })
        },
        deleteEvent(delete_block) {
            $('#deleteConfirm').modal('toggle');

            axios.post(this.deleteEndpoint, {
                _token: this.token,
                id: this.selectedEvent.id,
                delete_block: delete_block,
            }).then((response) => {
                response.data.data.map(id => {
                    this.events = this.events.filter((event) => event.id != id);
                })
                this.resetQuestions();
            }).catch(err => {
                console.log(err.resources)
                Swal.fire({
                    title: 'Error!',
                    text: 'Could not delete your event. Please try again',
                    icon: 'error',
                    confirmButtonText: 'Close'
                })
            })
        },
        openScheduleAddModal() {
            $('#addBlock').modal();
        },
        closeAddBlock() {
            this.resetQuestions();
            $('#addBlock').modal('toggle');
        },
        submitBulkSchedule() {
            let days = [];
            this.week.map((day, key) => {
                if ($('#weekday-' + key).is(":checked")) {
                    days.push(day);
                }
            });



            if(typeof this.startTime != 'string') {
                this.startTime = this.startTime.HH + ':' + this.startTime.mm
            }

            if(typeof this.endTime != 'string') {
                this.endTime = this.endTime.HH + ':' + this.endTime.mm
            }

            console.log(this.startTime, this.endTime);

            let offset = new Date(`${this.startDate} ${this.startTime}`);
            let endOffset = new Date(`${this.startDate} ${this.endTime}`);

            const hrDuration = endOffset.getHours() - offset.getHours();

            this.duration =  endOffset.getMinutes() - offset.getMinutes();

            if (hrDuration <= 0 && this.duration < 1) {
                Swal.fire({
                    title: 'Error!',
                    text: 'End time cannot be less than start time',
                    icon: 'error',
                    confirmButtonText: 'Close'
                })
                return;
            }
            axios.post(this.bulkCreateEndpoint, {
                _token: this.token,
                type: this.edit ? 'single' : this.type,
                start_date: offset,
                end_date: this.endDate,
                duration: this.duration,
                days,
                id: this.edit ? this.selectedEvent.id : null
            }).then((response) => {
                response.data.data.map(schedule => this.events.push(schedule));
                $('#addScheduleOld').modal('hide');
                $('body').removeClass('modal-open');
                $('.modal-backdrop').remove();
                this.refreshEvents();
            }).catch(err => {
                console.log(err.resources)
                Swal.fire({
                    title: 'Error!',
                    text: 'Could not create your event. Please try again',
                    icon: 'error',
                    confirmButtonText: 'Close'
                })
            })
        },
        resetQuestions() {
            this.question1 = true;
            this.question2 = false;
            this.question3 = false;
        },
        confirmDelete() {
            $('#addScheduleOld').modal('toggle');
            $('#deleteConfirm').modal();
        },
        isChecked(day) {
            return this.inArray(day, this.defaultDays);
        },
        inArray(needle, haystack) {
            let length = haystack.length;
            for (var i = 0; i < length; i++) {
                if (haystack[i] == needle) return true;
            }
            return false;
        },
        check(id, id2 = null) {
            console.log(this.days);
            const identifier = $('#' + id);
            if (identifier.is(":checked")) {
                identifier.prop('checked', false);
            } else {
                identifier.trigger('click');

            }

            if (id2) {
                $('#' + id2).prop('checked', false);
            }
        }
    }
}
</script>

<style scoped>

</style>
