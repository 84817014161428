import $ from 'jquery';
window.$ = window.jQuery = $;
require('bootstrap');
window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

import Vue from 'vue'
import VCalendar from 'v-calendar';
import {BootstrapVue} from 'bootstrap-vue';
import _ from 'lodash';
Vue.use(VCalendar, {});
Vue.use(BootstrapVue);

const files = require.context('./', true, /\.vue$/i)
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))
$(document).ready(() => {
    const app = new Vue({
        el: '#agent-vue',
    });
}) 

