var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('dashboard',{attrs:{"uppy":_vm.uppy,"plugins":['Webcam'],"props":{
            theme: 'light',
            height: 300,
            showProgressDetails: true,
            autoOpenFileEditor: true,
             doneButtonHandler: () => {
                _vm.uppy.reset();
             },
             proudlyDisplayPoweredByUppy: false
        }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }