var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"pb-3",attrs:{"id":"thumbnailHeader"}},[_vm._v("Upload Thumbnail Image")]),_vm._v(" "),_c('dashboard',{attrs:{"id":"thumbnailDash","uppy":_vm.uppy,"props":{
            theme: 'light',
            height: 250,
            showProgressDetails: true,
            autoOpenFileEditor: true,
             doneButtonHandler: () => {
             }}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }