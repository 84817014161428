<template>
    <div id="form">
        <div class="steps">

            <div class="title" v-if="currentStep != 3 && currentStep != 4">Step {{currentStep}} of 3</div>
            <div class="title" v-if="currentStep == 3 && currentStep != 4">Last Step!</div>
            <div class="progress" v-if="currentStep != 4"><div class="bar" :style="{'width': progress+'%'}"></div></div>

            <div class="step1" v-if="currentStep == 1">
                <h2>First, tell us what kind of insurance you need?</h2>
                <div class="buttons-wrap three">
                    <div><button class="form-btn" @click="changeStep('Medicare Supplement',2)">Medicare Supplement</button></div>
                    <div><button class="form-btn" @click="changeStep('Medicare Advantage',2)">Medicare Advantage</button></div>
                    <div><button class="form-btn" @click="changeStep('Final Expense',2)">Final Expense (Burial)</button></div>
                </div>
            </div>

            <div class="step2" v-if="currentStep == 2">
                <h2>Awesome! Now tell us where you live?</h2>
                <div class="form_row one">
                    <div style="text-align:center;position:relative;">
                        <input name="zip" id="zip" v-model="formData.zip" class="form_input" placeholder="Zip Code"/>
                        <span class="field_error" v-if="errors.zip.length > 0" style="left:0;width:100%;">{{errors.zip}}</span>
                    </div>
                </div>
                <div class="buttons-wrap one pd">
                    <div><button class="form-btn" @click="changeStep('',3)">Continue</button></div>
                </div>
            </div>

            <div class="step3" v-if="currentStep == 3">
                <h2 class="nbp">Let us know who you are...</h2>
                <h4>It just makes it easier for agents to connect with you.</h4>
                <div class="form_row two">
                    <div>
                        <input name="firstName" id="firstName" v-model="formData.firstName" class="form_input" placeholder="First Name"/>
                        <span class="field_error" v-if="errors.firstName.length > 0">{{errors.firstName}}</span>
                    </div>
                    <div>
                        <input name="lastName" id="lastName" v-model="formData.lastName" class="form_input" placeholder="Last Name"/>
                        <span class="field_error" v-if="errors.lastName.length > 0">{{errors.lastName}}</span>
                    </div>
                    <div>
                        <input name="email" id="email" v-model="formData.email" class="form_input" placeholder="Your Email Address"/>
                        <span class="field_error" v-if="errors.email.length > 0">{{errors.email}}</span>
                    </div>
                    <div>
                        <input name="phone" id="phone" v-mask="'+1 ###-###-####'" v-model="formData.phone" class="form_input" placeholder="Your Mobile Phone"/>
                        <span class="field_error" v-if="errors.phone.length > 0">{{errors.phone}}</span>
                        <div class="phone_disclaimer">We will only send you transactional texts to confirm an agent introduction and request a review. If you choose an agent, we will provide that agent with your number.</div>
                    </div>
                </div>
                <div class="buttons-wrap one pd">
                    <div><button class="form-btn" @click="changeStep('',4)">Show Me My Agents</button></div>
                </div>
            </div>

            <div class="step4" v-if="currentStep == 4">
                <h2 class="nbp">Here are your available agents.</h2>
                <h4>Explore the details and connect with as many agents you want.</h4>
                <div class="agents-list">
                    <div class="agent" v-for="agent in list" :key="agent.id">
                        <div class="agent-photo"><img :src="agent.image"></div>
                        <div class="agent-description">
                            <div>
                                <h2>{{agent.name}}</h2>
                                <p>{{agent.experience}} Years Of Experience</p>
                            </div>
                            <div>
                                <div class="feature">
                                    <div>Insurance Brands</div>
                                    <div>{{agent.brands.join(', ')}}</div>
                                </div>
                                <div class="feature">
                                    <div>States Licensed In</div>
                                    <div>{{agent.states.join(', ')}}</div>
                                </div>
                                <div class="feature">
                                    <div>Products Sold</div>
                                    <div>{{agent.assist.join(', ')}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="agent-rating">
                            <div>
                                <div class="verified"><img :src="window.location.origin+'/wp-content/themes/intropops/img/verified.png'"></div>
                                <div class="rating">{{agent.rating.toFixed(1)}}</div>
                                <div class="stars">
                                    <template v-for="index in agent.rating">
										<span class="filled" :key="index">
											<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="20px" height="20px" viewBox="0 0 48 48" enable-background="new 0 0 48 48" xml:space="preserve">
											<polygon fill="#FFCA28" points="24,4.051 30.49,17.186 45,19.29 34.5,29.512 36.977,43.949 24,37.137 11.023,43.949 13.5,29.512   3,19.29 17.51,17.186 "/>
											</svg>
										</span>
                                    </template>
                                    <template v-for="index in 5-agent.rating">
										<span class="not-filled" :key="index">
											<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"  width="20px" height="20px" y="0px" viewBox="0 0 48 48" enable-background="new 0 0 48 48" xml:space="preserve">
											<path fill="#FFCA28" d="M24,13l2.9,5.9l0.9,1.9l2.1,0.3l6.5,0.9l-4.7,4.6l-1.5,1.5l0.4,2.1l1.1,6.5l-5.8-3.1l-1.9-1l-1.9,1l-5.8,3.1  l1.1-6.5l0.4-2.1l-1.5-1.5l-4.7-4.6l6.5-0.9l2.1-0.3l0.9-1.9L24,13 M24,4l-6.5,13.2L3,19.3l10.5,10.2L11,44l13-6.8L37,44l-2.5-14.5  L45,19.3l-14.5-2.1L24,4L24,4z"/>
											</svg>
										</span>
                                    </template>
                                </div>
                                <div class="reviews">{{agent.reviews}} Reviews & Rating</div>
                            </div>
                            <div class="buttons-wrap two">
                                <div><a href="#">View More Details</a></div>
                                <div><button class="form-btn-sm">Connect</button></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
name: "FormComponent"
}
</script>

<style scoped>

</style>
